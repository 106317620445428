@import url('https://fonts.googleapis.com/css?family=Manrope');

.AppBody {
  text-align: center;
  font-family: 'Manrope';
  background-color:white;
  min-height: 86vh;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  color: white;
  margin: 0;
  padding: 0;
  height: 100%;
}

.background {
  height: 88vh;
}

body::-webkit-scrollbar {
  width: 0em; 
  background-color: #F5F5F5; 
}

body::-webkit-scrollbar-thumb {
  background-color: #000;  
}

/*
.App{
  background-color: #141436;
}
*/

.personalize_icon {
  width: 50px;
  height: 50px;
  align-self: center;
  margin-right: 10px;
}

.Page {
  width: 100%;
  height: auto;
}
.Appheader {
  background-color: #141436;
}
.TopSection {
  height: 4vh;
  background-color: #141436;
}
select {
  font-family: 'Manrope';
  width: 70px;
  height: 35px;
}
input {
  font-family: 'Manrope';
}