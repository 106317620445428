.info-titulo {
    text-align: left;
    margin-bottom: 20px;
  }
  
.segunda_tabla_container {
    margin-top: 50px;
}

.td {
  text-align: center;
}

.th {
  text-align: center;
}

#Omitir {
  text-align: center;
  vertical-align: middle;
}

.title-and-button-ensayos-cuantificacion {
  height: auto;
  display: grid;
  grid-template-columns: 70% 30%;
  grid-template-areas: "title-cuantificacion select-intento";
}

.title-and-button-ensayos-cuantificacion-title {
  grid-area: title-cuantificacion;
  padding-bottom: 5%;
  text-align: left;
}

.title-and-button-ensayos-cuantificacion-select {
  grid-area: select-intento;
  margin-top: 2%;
  text-align: left;
  justify-items: center;
}

.select-intento {
  width: auto;
}