body {
    background-color: #FFFFFF;
} 

.Google {
    width: 40px;
    padding-left: 20%;
}
  
.Vertical {
    background-color: #FFFFFF;
    color: black;
    display: flex;
    flex-direction: column;
    width: 70%;
    padding-top: 2%;
    margin-top: 5%;
    padding-bottom: 4%;
    margin-left: 10%;
    margin-right: 10%;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.5); /* Adjust the values as needed */
}

.Horizontal {
    display: flex;
    flex-direction: row;
    padding-left: 10%;
    padding-right: 10%;
    height: 55px;
}

.Horizontal-title {
    display: flex;
    flex-direction: row;
    padding-left: 10%;
    padding-right: 10%;
    height: 75px;
}

.Element {
    align-self: center;
    width: 100%;
}

.Right {
    width: 100%;
    padding-left: 3%;
}


.input {
    width: 100%;
    color: #000000;
    border: none;
    border-bottom: solid 2px;
    padding: 0%;
    padding-left: 10px;
    border-radius: 0px;
    outline: none;
    font-size: 20px;
}

.input:focus {
    color: black;
    border-bottom: solid 2px #000000;
}

a {
    font-size: 18px;
    margin-right: 15%;
    text-decoration: none;
    color: #0052D1;

}

h2 {
    margin: 0;
}

::placeholder {
    color: #B4B9C7;
}

.Link {
    padding-top: 10px;
    text-align: right;
    width: 100%;
}

.Text {
    height: 80px;
    width: 70%;
    text-align: left;
}

.ElementSign {
    width: 100%;
    margin-top: 5%;
}

.Sign {
    margin-right: 0;
    border: none;
    border-radius: none;
    padding: 3%;
    padding-left: 10%;
    padding-right: 10%;
    background-color: #E8FF06;
    color: #141436;
}


.Password {
    height: 80px;
    width: auto;
    text-align: left;
}
.Search {
    margin-top: 25px;
    margin-left: 10px;
    width: 20px;
    height: 20px;
}