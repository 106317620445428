body {
    background-color: #FFFFFF;
} 

.LoginTitle {
    width: 100%;
    margin-bottom: 5%;
    margin-top: 0;
}

h1 {
    font-size: 70px;
    text-align: left;
    padding-left: 7%;
}