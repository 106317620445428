.info-grid-fecha_consolidado {
    margin-left: 275px;
    text-align: left;
  }
.solicitud_container_2{
    background-color: white;
    margin-right: 5%;
    margin-left: 5%;
    margin-top: 2%;
    margin-bottom: 2%;
    /*borde redondo*/
    /*alargar container*/
    padding: 2%;
    display: flex;
    flex-direction: column;
    color: white;
}

.title-and-button-ensayos-consolidacion-select {
  display: flex;
  flex-direction: row;
  margin-top: 2%;
  text-align: left;
  justify-items: center;
}

.select-intento {
  width: auto;
  margin-left: 10%;
  align-self: center;
}