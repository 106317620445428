.loading-div-qpcr {
    position: absolute;
    top: 55%;
    left: 46%;
  }

  .alert {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .alert-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 4px;
    width: 400px;
  }  
  
  .alert-close {
    background: none;
    border: none;
    cursor: pointer;
    margin: 0;
    padding: 0;
    margin-left: 90%;
  }
  
  .alert-close img {
    width: 20px;
    height: 20px;
  }
  
  .alert-body {
    margin-bottom: 20px;
  }
  
  .alert-footer {
    display: flex;
    justify-content: space-around;
  }
  
  .alert-button {
    margin-left: 10px;
  }