.Navbar {
    height: 12vh;
    display: flex;
    flex-direction: row;
    background-color: #141436;
}

.Navbar-Title {
    width: 30%;
    margin-right: 30%;
    padding-top: 1%;
    margin-left: 2%;
}

.Navbar-Links {

    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;

}

.Navbar-Links li {
    margin: 0 1rem;
    cursor: pointer;

}

.Navbar-Link {
    color: white;
    text-decoration: none;
}

.Navbar-Link:hover {
    color: #0052D1;
}

.Navbar-DropdownLink:hover {
  color: #0052D1;
}

.Navbar-Link:active {
  color: #0052D1;
}

.Navbar-Dropdown {
  position: relative;
  display: inline-block;
  margin-right: 50px;
}

.Navbar-DropdownButton {
  border: none;
  cursor: pointer;
  border-radius: 0;
  padding: 0;
  margin: 0;
  width: 100%;
  background-color: #141436;
}

.Navbar-DropdownContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  right: 0px;
  background-color: #FFFFFF;
  border: solid 1px;
  border-radius: 3px;
  width: auto;
  min-width: 100px; /* Set a minimum width to prevent content from overflowing */
  z-index: 1;
  padding-bottom: 10px;
}

.Navbar-DropdownLink {
  color: black;
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  width: 100%; /* Make the links occupy the full width of the dropdown content */
  box-sizing: border-box; /* Include padding in the width calculation */
}


.Settings {
  height: 50px;
  background-color: #141436;
}


.Button-Container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 15px;
}

.GoBackButton {
  font-size: 12px;
}
