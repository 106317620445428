.grid-info-reporte {
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
    grid-template-areas: 
    "cliente cliente-info cliente-info cliente-info"
    "columna1-encabezado columna1-info columna2-encabezado columna2-info"
    "observacion observacion-info observacion-info boton-editar";
    row-gap: 0px;
}
.grid-info-reporte .cliente {
    grid-area: cliente;
    text-align: left;
}
.grid-info-reporte .cliente-info {
  grid-area: cliente-info;
  text-align: left;
}
.grid-info-reporte .columna1-encabezado {
    grid-area: columna1-encabezado;
    text-align: left;
}
.grid-info-reporte .columna2-encabezado {
    grid-area: columna2-encabezado;
    text-align: left;
}
.grid-info-reporte .columna1-info {
  grid-area: columna1-info;
  text-align: left;
}
.grid-info-reporte .columna2-info {
  grid-area: columna2-info;
  text-align: left;
}

.grid-info-reporte .observacion {
    grid-area: observacion;
    text-align: left;
}

.grid-info-reporte .observacion-info {
  grid-area: observacion-info;
  text-align: left;
}

.grid-info-reporte .boton-editar {
  grid-area: boton-editar;
  text-align: right;
}

.info-reporte {
    font-size: 15px;
}
.titulo-reporte {
  text-align: left;
}

.grid-titulo-descarga {
    display: grid;
    grid-template-columns: 75% 25%;
    grid-template-areas: 
    "titulo descarga";
    row-gap: 0px;
}
.grid-titulo-descarga .titulo {
    grid-area: titulo;
    text-align: left;
}
.grid-titulo-descarga .descarga {
    grid-area: descarga;
    text-align: right;
}

.grid-select-intentos-reporte {
    display: grid;
    grid-template-columns: 25% 75%;
    grid-template-areas: 
    "select-intento button-descarga";
    row-gap: 0px;
}

.select-intento-reporte {
    grid-area: select-intento;
    text-align: left;
}

.select-intento {
    width: auto;
}

.button-descarga-reporte {
    grid-area: button-descarga;
    text-align: right;
}

.info-reporte-obs {
  font-size: 15px;
  text-align: left;
}

.grid-reporte-obs-edit {
  display: grid;
  grid-template-columns: 75% 25%;
  grid-template-areas: 
  "obs-reporte edit-headers";
  row-gap: 0px;
}

.obs-reporte {
  grid-area: obs-reporte;
  text-align: left;
}

.edit-headers {
  grid-area: edit-headers;
  text-align: right;
}

.modal-reporte {
  margin: 15%;
  font-family: 'Manrope';
  background-color: white;
}

.form-modal-reporte {
  background-color: white;
  margin: 5%;
  padding-top: 2.5%;
  align-items: center;
  text-align: center;
}