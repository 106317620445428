.container-principal
{
    display: grid;
    grid-template-columns: auto 40% 50% auto;
    grid-template-rows: auto;
    grid-template-areas: 
      "esp-1 col-1 col-2 esp-2";
    padding: 15px;
    grid-column-gap: 25px;
}
.container-principal-esp-1 {
  grid-area: esp-1;
}
.container-principal-esp-2 {
  grid-area: esp-2;
}
.container-principal-col-1 {
  grid-area: col-1;
}
.container-principal-col-2 {
  grid-area: col-2;
}