.container-principal-curva{
    background-color: white;
    display: flex;
    flex-direction: column;
    color: #B4B9C7;
    margin-right: 50px;
    margin-left: 50px;
    margin-top: 20px;
    padding-bottom: 20px;
    text-align: start;
}

.curva_title{
    display: flex;
    flex-direction: row;
    margin-left: 20px;
    margin-right: 20px;
    font-size: 30px;
    color: black;
}

.chartContainer{
    display: flex;
    flex-direction: row;
    width: 700px;
    height: 300px;
    margin-left: 50px;
    margin-bottom: 100px;

}
.grafico_y_tablas{
    display: flex;
    flex-direction: row;
    width: 100%;
    margin: 0;
    flex: auto;
}
h4{
    color: black;
    margin-top: 10px;
}
.titulo_y_grafico{
    display: flex;
    flex-direction: row;
}
.graf_y_titulo{
    margin-left: 65px;
}

.tablas {
    width:50%;
    padding-left: 10px;
    justify-content: space-between;
    padding-right: 10px;
}
.boton_y_titulo{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

#boton-esquina-curva {
    margin-top: 33%;
}

.select-intento-curva {
    margin: 10%;
    margin-top: 50%;
    margin-left: 0;
    width: auto;
}

/* .title-and-button-ensayos-qpcr-button{
    height: 50px;
    width: 200px;
    margin-top: 40px
} */
.formulario_1_input{
    margin-bottom: 50px;
}
.modal-qpcr_2 {
    margin-top: 3%;
    margin-right: 10%;
    margin-left: 10%;
    font-family: 'Manrope';
  }
.reactivos_title{
    display: flex;
    flex-direction: row;
    margin-left: 20px;
    margin-right: 20px;
    font-size: 30px;
    color: black;
}
.parametros_reg_lineal_2{
   margin-top: 20px;
}	
.parametros_reg_lineal_i{
    margin-top: 50px;
 }	

.titulo_y_boton{
    display: flex;
    flex-direction: row;
}
.boton_subir{
    background-color: #E8FF02;
}
.boton_subir:hover {
    background-color: #becc23;
}
.modal-qpcr_2 {
    background-color: rgba(255, 255, 255, 0.9);
    overflow: hidden;
    border: none;
}

.darken-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 9998;
}

.titulos_container{
    display: flex;
    flex-direction: row;
}
.info-subtitulo{
    margin-left: 350px;
    font-size: 15px;
    color: black;
    font-weight: bold;
}

.tabla_cuantificacion th:nth-child(13) {
    border-left: 1px solid black;
  }

.tabla_cuantificacion td:nth-child(13) {
    border-left: 1px solid black;
  }
  
  
  
  