body {
    background-color: #141436;
}

.solicitudes_titulo {
    font-size: 30px;
    color: black;
    font-weight: bold;
    margin-top: 15px;
    margin-bottom: 15px;
    z-index: 1; 
    position: sticky;
    top: 0;
    text-align: left;
    margin-left: 20px;
}

.solicitudes_header{
    display: flex;
    flex-direction: row;
}
p {
    font-size: 20px;
    color: black;
}
.solicitudes_container{
    background-color: white;
    display: flex;
    flex-direction: column;
    color: white;
    height: 70vh;
    margin-left: 20px;
    overflow-y: scroll;
}

.search-bar{
    margin-top: 20px;
    margin-left: 100px;
}
.input_search{
    margin-right: 5px;
    width: 150px;
    padding: 6px;
}

.select_filter {
    margin-right: 5px;
    width: 100px;
    border-radius: 0;
}


.solicitudes_container::-webkit-scrollbar {
    left: 20px;
    width: 20px;
}

.solicitudes_container::-webkit-scrollbar-thumb {
background-color: #141436;
border-radius: 10px;
border: 2px solid #B4B9C7;
}

.solicitudes_container::-webkit-scrollbar-thumb:hover {
background-color: #0052D1;
}

.solicitudes{
    background-color: white;
    margin-right: 1.5%;
    color: #B4B9C7;
    padding: 10px;
    margin-top: 70px;
}

.solicitud_container_info_and_flask{
    display: flex;
    flex-direction: row;
    text-align: left;
    border-bottom: 1px solid black;
}


.icon_agenda {
    font-size: 2.5rem;
    color: black; 
    vertical-align: middle;
    margin-top: 18px; 
    margin-right: 20px;
}

.icon_ensayo {
    font-size: 2.5rem;
    color: black; 
    vertical-align: middle;
    margin-top: 18px; 
    margin-right: 10px;
}

.icon_flecha {
    font-size: 2.5rem;
    color: black; 
    vertical-align: middle;
    margin-top: 18px; 
    margin-right: 20px;

}

#tiny {
    font-size: 1.5rem;
}
.solicitud_id {
    color: black;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 0px;
}
.solicitud_sola_info_container{
    margin-right: 420px;
}
.solicitud_prioridad {
    margin-top: 0px;
    margin-bottom: 0px;
    color: black;
    font-size: 12px;
    color: #141436
}
.solicitud_fecha {
    margin-top: 0px;
    color: black;
    font-size: 12px;
}

.link_ensayo {
    font-size: 15px;
}

.links_de_ensayos{
    display: flex;
    flex-direction: column;
    margin-top: 15px;
}
.ensayo_texto {
    display: inline-block;
}


.botonera {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.ver_solicitud{
    font-size: 15px;
    width: auto;
    align-self: center;
    align-items: center;
    background-color: #FFFFFF;
    color: #0052D1;
    width: 300px;
    border-radius: 40px;
    padding: 0px;
    align-content: center;
    text-align: center;
}

.sol_info{
    margin-right: 300px;
    display: flex;
    flex-direction: column;
}

.boton-grid {
    display: grid;
    grid-template-columns: auto auto;
    grid-template-areas: "icon-grid text-grid";
    column-gap: 0px;
    align-items: center;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 0px;
    padding-bottom: 0px;
}

.icon-grid {
    grid-area: icon-grid;
    width: 25px;
    height: 25px;
    align-self: center;
    padding-right: 0px;
}

.text-grid {
    grid-area: text-grid;
    font-size: 15px;
    color: white;
    padding-left: 0px;
    padding: 0px;
}

/* Nuevos grid botonera */
.grid-botonera-solicitudes {
    display: grid;
    grid-template-columns: 25% auto auto 10%;
    grid-template-rows: auto auto auto;
    grid-template-areas: 
    "titulo-bs espacio-bs espacio-bs flecha-bs"
    "prioridad-bs espacio-bs espacio-bs flecha-bs"
    "fecha-bs espacio-bs espacio-bs flecha-bs";
    column-gap: 0px;
    cursor: pointer;
}

.grid-botonera-solicitudes .icono-bs {
    grid-area: icono-bs;
    width: 25px;
    height: 25px;
    align-self: center;
    align-items: center;
    align-content: center;
    padding-right: 0px;
    justify-content: center;
}

.grid-botonera-solicitudes .titulo-bs {
    grid-area: titulo-bs;
    font-size: 15px;
    text-align: left;
}

.grid-botonera-solicitudes .prioridad-bs {
    grid-area: prioridad-bs;
    font-size: 15px;
    text-align: left;
}

.grid-botonera-solicitudes .fecha-bs {
    grid-area: fecha-bs;
    font-size: 15px;
    text-align: left;
}

.grid-botonera-solicitudes .espacio-bs {
    grid-area: espacio-bs;
    font-size: 15px;
}

.grid-botonera-solicitudes .flecha-bs {
    grid-area: flecha-bs;
    align-items: center;
    width: 25px;
    height: 25px;
    justify-content: center;
}

/* Nuevos grid botonera links */
.grid-botonera-solicitudes-links {
    display: grid;
    grid-template-rows: 50% auto;
    grid-template-areas: 
    "columna-links-bsl espacio1-bsl";
    column-gap: 0px;
}

.grid-botonera-solicitudes-links .espacio1-bsl {
    grid-area: espacio1-bsl;
    font-size: 15px;
}

.grid-botonera-solicitudes-links .columna-links-bsl {
    grid-area: columna-links-bsl;
    font-size: 15px;
    text-align: center;
}

/* Nuevos botones con links */
.button-solicitudes-grid {
    display: grid;
    grid-template-columns: 25% auto;
    grid-template-areas: "bsl-icon bsl-title";
    column-gap: 0px;
    align-items: center;
    padding-left: 15px;
    padding-right: 15px;
    background-color: #0052D1;
    border-radius: 25px;
    cursor: pointer;
}

.button-solicitudes-grid:hover {
    background-color: #141436;
}

.button-solicitudes-icon {
    grid-area: bsl-icon;
    align-items: center;
}

.button-solicitudes-title {
    grid-area: bsl-title;
    color: white;
    font-size: 13px;
    text-align: right;
}

.icon-solicitudes-grid {
    height: 30px;
    width: 30px;
    padding-top: 6px;
    padding-bottom: 3px;
    display: flex;
    flex: left;
}

img {
    width: 50px;
    height: auto;
}