.white-div {
  background-color: #FFFFFF;
  color: #FFFFFF;
  margin: 12%;
  padding: 5%;
  height: 50%;
}

.dropzone {
  display: flex;
  background-color: white;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 5px dashed #B4B9C7;
  height: 72%;
  transition: border-color 0.2s ease-in-out;
  padding-top: 5%;
  padding-bottom: 12%;
}
.dropzone:hover {
  border-color: #E8FF02;
}

.dropzone.dragging {
  border-color: #E8FF02;
}

.dropzone.hovering {
  border-color: #E8FF02;
}

h3 {
    color: black;
    font-size: 20px;
    list-style-type: none;

}

.actions {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 16px; /* o la separación que desees */
  }

button {
    background-color: #E8FF02; /* amarillo */
    color: #000000; /* negro */
    border: none;
    font-size: 20px;
    padding: 10px;
    margin-bottom: 30px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
  }
  
button {
    transform: scale(1.1);
}

.column {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.encargado {
  text-align: left;
  font-weight: bold;
}