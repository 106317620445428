body {
    background-color: #FFFFFF;
}  

.Login {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.Row {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.LogoPhage {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center; 
}

.LogoImg {
    width: 220px;
    height: 50px;
}

.Container {
    padding: 5%;
}
