body {
    background-color: #FFFFFF;
} 

.row {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40%;
    color: white;
}

.message {
    color: white;
}