@import url('https://fonts.googleapis.com/css?family=Manrope');

body {
  background-color: #141436;
}

h2 {
  color: #141436;
}

.solicitud_container{
    background-color: white;
    margin-right: 5%;
    margin-left: 5%;
    margin-top: 2%;
    margin-bottom: 2%;
    /*borde redondo*/
    /*alargar container*/
    padding: 2%;
    display: flex;
    flex-direction: column;
    color: white;
}

.label_header{
    color: black;
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 0px;
}

.content_header{
    margin-top: 0px;
    color: #B4B9C7;
    font-size: 12px;
}
.solicitud_info_container {
    display: flex;
    width: 100%;
    flex: 1;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }


.columna_container {
    width: 100%;
    flex: 1;
}
.muestras_container {
  margin-top: 20px;
  padding: 20px;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th, td {
  font-family: 'Manrope', sans-serif;
  padding: 10px;
  text-align: left;
  font-size: 15px;
}

th {
  background-color: #0052D1;
  font-weight: bold;

}

tbody tr:nth-child(even) {
  background-color: #f5f5f5;
}

tbody tr:nth-child(odd) {
  border-top: 1px dashed #d4d4d4;
}

tbody tr:first-child {
  color: #444
;
}

th {
    color: white
}
table tr:not(:last-child) {
  border-bottom: 1px solid #ccc; /* Cambia el color de las líneas según tus necesidades */
}
td {
    color: black
}

.info-grid {
  display: grid;
  grid-template-columns: auto auto 15% 15%;
  grid-column-gap: 2.5%;
  grid-template-areas:
  "titulo  espacio fecha recepcion"
  "dragNdrop espacio solicitante ensayos"
  "dragNdrop espacio prioridad encargado";
}

.info-grid-titulo {
  margin-left: 3%;
  grid-area: titulo;
  text-align: left;
}

.info-grid-fecha {
  grid-area: fecha;
  text-align: left;
}

.info-grid-recepcion {
  grid-area: recepcion;
  text-align: left;
}

.info-grid-dragNdrop {
  grid-area: dragNdrop;
  text-align: left;
  padding: 15px;
  padding-right: 30px;
}

.info-grid-solicitante {
  grid-area: solicitante;
  text-align: left;
}

.info-grid-ensayos {
  grid-area: ensayos;
  text-align: left;
}

.info-grid-prioridad {
  grid-area: prioridad;
  text-align: left;
}

.info-grid-encargado {
  grid-area: encargado;
  text-align: left;
}

.button-raw-grid {
  display: grid;
  grid-template-columns: auto auto;
  grid-template-areas: "raw-icon raw-title";
  column-gap: 0px;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
  background-color: #0052D1;
  border-radius: 25px;
  cursor: pointer;
}

.button-raw-grid:hover {
  background-color: #141436;
}

.button-raw-icon {
  grid-area: raw-icon;
  align-items: center;
}

.button-raw-title {
  grid-area: raw-title;
  color: white;
  font-size: 15px;
}

.icon-raw-grid {
  height: 25px;
  width: 25px;
}

.modal-qpcr {
  margin: 15%;
  font-family: 'Manrope';
}

/* qpcr */
.title-qpcr {
  text-align: left;
  width: auto;
}

.button-raw-grid_inf:hover {
  background-color: #e5e5e5;
}

.button-raw-grid_inf {
  margin-top: 30px;
  display: grid;
  grid-template-columns: auto auto;
  grid-template-areas: "raw-icon raw-title";
  column-gap: 0px;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
  background-color: #f5f5f5;
  border-radius: 25px;
  cursor: pointer;
}

#boton-esquina {
  width: auto;
  margin-top: 3%;
}

.title-and-button-ensayos-qpcr {
  margin-left: 2%;
  margin-right: 2%;
  height: auto;
  display: grid;
  grid-template-columns: 30% 25% 45%;
  grid-template-areas: "title-qpcr select-intento button-upload-qpcr";
}

.title-and-button-ensayos-qpcr-title {
  grid-area: title-qpcr;
}

.title-and-button-ensayos-qpcr-select {
  grid-area: select-intento;
  margin-top: 2%;
  text-align: left;
  justify-items: center;
}

.title-and-button-ensayos-qpcr-button {
  grid-area: button-upload-qpcr;
  align-items: right;
}

.select-intento {
  width: auto;
}

.loading-div-solicitudes {
  position: absolute;
  top: 50%;
  left: 70%;
}

.loading-div-solicitud {
  position: absolute;
  top: 20%;
  left: 45%;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 4px;
  width: 400px;
}

.modal-header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  text-align: center;
}

.modal-header h4 {
  flex-grow: 1;
}


.modal-close {
  background: none;
  border: none;
  cursor: pointer;
  margin: 0;
  padding: 0;
  margin-left: 90%;
}

.modal-close img {
  width: 20px;
  height: 20px;
}

.modal-body {
  margin-bottom: 20px;
}

.modal-footer {
  display: flex;
  justify-content: space-around;
}

.modal-button {
  margin-left: 10px;
}

