body {
  background-color: #141436;
}

.nueva_solicitud_title{
    font-size: 30px;
    color: white;
    margin-top: 25px;
    margin-bottom: 15px;
    margin-left: 55px;
    text-align: left;
  }


.Create_solicitud{
    background-color: #141436;
    margin-top: 70px;
    margin-left: 1.5%;
    margin-right: 1.5%;
    /*borde redondo*/
    /*alargar container*/
    display: flex;
    flex-direction: column;
    color: #141436;
}
