
.dropzone1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 60px;
  margin-right: 60px;
  align-items: center; 
  height: 250px;
  border: 5px dashed #B4B9C7;
  padding: 2rem;
  margin-bottom:  75px;
  margin-top: 30px;
  transition: border-color 0.2s ease-in-out;
}
  
  .dropzone1:hover {
    border-color: #E8FF02;
  }
  
  .dropzone.dragging1 {
    border-color: #E8FF02;
  }
  
  .dropzone.hovering1 {
    border-color: #E8FF02;
  }

.title-drag {
    color: black;
    font-size: 20px;
    list-style-type: none;


}

.actions1 {
    display: flex;
    gap: 16px; /* o la separación que desees */
  }

.button-drag {
    background-color: #E8FF02; /* amarillo */
    color: #000000; /* negro */
    border: none;
    font-size: 20px;
    padding: 10px;
    border-radius: 10px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
  }
  
.button-drag {
  transform: scale(1.1);
}

.loading-div-qpcr-upload {
  position: relative;
  left: 30%;
}