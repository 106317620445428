/* Grid Principal */
.grid-principal-ensayos {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 2.5% 5%;
}

.grid-principal-ensayos-menu {
  grid-area: ensayos-menu;
  background-color: white;
  text-align: center;
  color: #000000;
  width: 700px;
}

/* Botonera DropDown */
.grid-botonera-ensayos {
  display: grid;
  grid-template-columns: 10% 70% 20%;
  grid-template-areas: 
  "icono-ensayo-botonera nombre-ensayo-botonera flecha-botonera";
  grid-gap: 2.5%;
  margin-left: 10%;
  margin-right: 10%;
  margin-top: 2.5%;
  margin-bottom: 2.5%;
  color: #000000;
  cursor: pointer;
}

.grid-botonera-ensayos-icono-ensayo-botonera {
  grid-area: icono-ensayo-botonera;
  color: #000000;
  align-items: center;
  justify-content: center;
  margin-right: 450px;
}

.grid-botonera-ensayos-nombre-ensayo-botonera {
  grid-area: nombre-ensayo-botonera;
  color: #000000;
  align-items: left;
  text-align: left;
  font-size: 20px;
}

.grid-botonera-ensayos-flecha-botonera {
  grid-area: flecha-botonera;
  margin-left: 50px;
  color: #000000;
  align-items: center;
  justify-content: center;
}

.linea-botonera-borde-bajo {
  display: flex;
  flex-direction: row;
  margin-left: 20px;
  margin-right: 20px;
  text-align: left;
  border-bottom: 1px solid black;
}

.title-ensayo-sol {
  align-items: left;
  font-size: 30px;
}

/* Nuevos botones con links */
.button-ensayos-grid {
  display: grid;
  grid-template-columns: 25% auto;
  grid-template-areas: "bsl-icon bsl-title";
  column-gap: 0px;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
  background-color: #E8FF02;
  cursor: pointer;
}

.button-ensayos-grid:hover {
  background-color: #becc23;
}

.button-ensayos-icon {
  grid-area: bsl-icon;
  align-items: center;
}


.icon-ensayos-grid {
  height: 25px;
  width: 25px;
}

.tiny-space {
  font-size: 15px;
}
.solicitud_titulo{
  font-size: 30px;
  color: black;
  font-weight: bold;
  margin-top: 30px;
  margin-bottom: 40px;
  z-index: 1; 
  position: sticky;
  top: 0;
  text-align: left;
  margin-left: 40px;
}
.container_ensayos {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
}

.ensayo_titulo{
  font-size: 25px;
  color: black;
  font-weight: bold;
  margin-top: 30px;
  margin-bottom: 7.5px;
  z-index: 1; 
  position: sticky;
  top: 0;
  text-align: left;
}
.container_ensayo_header{
  border-bottom: 1px solid black;
  margin-bottom: 30px;
}
.container_ensayo{
  margin-right: 40px;
  margin-left: 40px;
}
.boton_buscar{
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15%;
}
.boton_buscar:hover {
  background-color: #becc23;
}
.boton_y_descripcion{
  display: flex;
  align-items: center;
  margin-right: 70px;
}
.descripcion_ensayo{
  padding-bottom: 20px;
  white-space: nowrap;
}

.loading-div-ensayos {
  position: absolute;
  top: 45%;
  left: 46%;
}